/*
  Icon Media Hub API v1
  https://www.iconfigurators.app/hub/v1
  */

// http://iconfigurators.local/hub/v1/index.cfm?endpoint=%2Fwheels%2FoeStandard&id=13898&bolt=5x114.3&hub=70.60&minoff=30&maxoff=45&diam=20&width=8&fmk=140490
/* SAMPLE

  http://iconfigurators.local/hub/v1/index.cfm?
  endpoint=%2Fwheels%2FoeStandard
  id=13898
  bolt=5x114.3
  hub=70.60
  minoff=30
  maxoff=45
  diam=20
  width=8
  fmk=140490
 */
//import config from '@/lib/config';
import axios from 'axios';

const fetcher = axios.create({
  baseURL: 'https://dev2.iconfigurators.app/hub/v1/index.cfm?'
  //baseURL: 'http://iconfigurators.local/hub/v1/index.cfm?'
});

export const Wheels = {
  oeStandard(opts = {}) {
    // opts = { id, bolt, hub, minoff, maxoff, diam, width, fmk }
    opts.endpoint = '/wheels/oeStandard';
    return fetcher.get('', { params: opts });
  },

  standard(opts = {}) {
    // opts = { id, bolt, hub, minoff, maxoff, diam, width, fmk }
    opts.endpoint = '/wheels/standard';
    return fetcher.get('', { params: opts });
  },

  staggered(opts = {}) {
    // opts = { id, bolt, hub, minoff, maxoff, diam, width, fmk }
    opts.endpoint = '/wheels/staggered';
    return fetcher.get('', { params: opts });
  },

  lifted(opts = {}) {
    // opts = { id, bolt, hub, minoff, maxoff, diam, width, fmk }
    opts.endpoint = '/wheels/lifted';
    return fetcher.get('', { params: opts });
  },

  customStandard(opts = {}) {
    // opts = { id, bolt, hub, minoff, maxoff, diam, width, fmk }
    opts.endpoint = '/wheels/customStandard';
    return fetcher.get('', { params: opts });
  },

  media(opts = {}) {
    // opts = { wheelImageId }
    opts.endpoint = '/media';
    opts.configId = 13898;
    return fetcher.get('', { params: opts });
  }
};

export const Vehicles = {
  vehicle(opts = {}) {
    opts.endpoint = '/vehicles';
    return fetcher.get('', { params: opts });
  },

  bodyTypes(opts = {}) {
    opts.endpoint = '/bodytypes';
    return fetcher.get('', { params: opts });
  },

  gallery(opts = {}) {
    opts.endpoint = '/galleries';
    opts.configId = 13898;
    return fetcher.get('', { params: opts });
  },

  getYears(opts = {}) {
    opts.endpoint = '/getYears';
    opts.configId = 13898;
    return fetcher.get('', { params: opts });
  },

  getMakes(opts = {}) {
    opts.endpoint = '/getMakes';
    opts.configId = 13898;
    return fetcher.get('', { params: opts });
  },

  getModels(opts = {}) {
    opts.endpoint = '/getModels';
    opts.configId = 13898;
    return fetcher.get('', { params: opts });
  },
  getSizesByFMK(opts = {}) {
    opts.endpoint = '/getSizesByFMK';
    //opts.configId = 13898; // WheelMax
    return fetcher.get('', { params: opts });
  }
};
