<template>
  <div id="app">
    <label>
      <select v-model="year" id="icm-years-select">
        <option value="null" disabled>Select Year</option>
        <option v-for="year in years" :value="year.key" :key="year.year">
          {{ year.value }}
        </option>
      </select>
    </label>
    <label>
      <select v-model="make" id="icm-makes-select" :disabled="!makes.length">
        <option value="null" disabled>Select Make</option>
        <option v-for="make in makes" :value="make.key" :key="make.key + '-make'">
          {{ make.value }}
        </option>
      </select>
    </label>
    <label>
      <select v-model="model" id="icm-models-select" :disabled="!models.length">
        <option value="null" disabled>Select Model</option>
        <option v-for="model in models" :value="model.key" :key="model.key + 'model'">
          {{ model.value }}
        </option>
      </select>
    </label>
    <label v-show="drivebodies.length > 0">
      <select v-model="drivebody" id="icm-drivebodies-select" :disabled="!drivebodies.length">
        <option value="null" disabled>Select Submodel</option>
        <option v-for="drivebody in drivebodies" :value="drivebody.id" :key="drivebody.smartDescription + '-submodel'">
          {{ drivebody.smartDescription }}
        </option>
      </select>
    </label>
    <label v-show="submodels.length > 1">
      <select v-model="submodel" id="icm-submodels-select" :disabled="!submodels.length">
        <option value="null" disabled>Select OE Rim Size</option>
        <option v-for="submodel in submodels" :value="submodel.fmk" :key="submodel.fmk.toString() + '-smartsize'">
          {{ submodel.rimDiameter }}x{{ submodel.rimWidth.toFixed(1) }}
        </option>
      </select>
    </label>
    <label v-show="sizeCategories.length > 1">
      <select v-model="selectedSizeCategory" id="icm-size-category-select" :disabled="!sizeCategories.length">
        <option value="null" disabled>Select Size Category</option>
        <option v-for="cat in sizeCategories" :value="cat" :key="cat + '-category'">
          {{ cat }}
        </option>
      </select>
    </label>
    <label v-show="sizesForSelectedCategory.length > 0">
      <select v-model="size" id="icm-sizes-select" :disabled="!sizesForSelectedCategory.length">
        <option value="null" disabled>Select Size</option>
        <option v-for="size in sizesForSelectedCategory" :value="size.sizeId" :key="size.sizeId + '-sizeId'">
          {{ getSizeDescription(size) }}
        </option>
      </select>
    </label>
    <!---label>
      <select v-model="size" id="icm-sizes-select" :disabled="!sizes.length">
        <option value="null" disabled>Select Size</option>
        <option v-for="size in sizes" :value="size.sizeId" :key="size.sizeId + '-sizeId'">
         {{ getSizeDescription(size) }}
        </option>
      </select>
    </label--->
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'app',
  /*
  components: {
    HelloWorld
  } */
  data() {
    return {
      selected: null,
      selectedSizeCategory: null
    };
  },
  computed: {
    year: {
      get() {
        return this.selectedYear;
      },
      set(year) {
        this.setYear(year);
        this.selectedSizeCategory = null;
      }
    },
    make: {
      get() {
        return this.selectedMake;
      },
      set(make) {
        this.setMake(make);
        this.selectedSizeCategory = null;
        // this.$store.commit('SELECT_MAKE', value);
      }
    },
    model: {
      get() {
        return this.selectedModel;
      },
      set(model) {
        this.setModel(model);
        this.selectedSizeCategory = null;
      }
    },
    drivebody: {
      get() {
        return this.selectedDriveBody;
      },
      set(drivebody) {
        this.setDriveBody(drivebody);
        this.selectedSizeCategory = null;
      }
    },
    submodel: {
      get() {
        return this.selectedSubModel;
      },
      set(submodel) {
        //console.log('set submodel', submodel);
        this.setSubModel(submodel);
        this.selectedSizeCategory = null;
        //this.$store.commit('SELECT_SUBMODEL', value);
      }
    },
    size: {
      get() {
        return this.selectedSize;
      },
      set(size) {
        this.setSize(size);
        // this.$store.commit('SELECT_SIZE', value);
      }
    },
    sizeCategories() {
      return [...new Set(this.sizes.map(e => e.fitmentNote))];
    },
    sizesForSelectedCategory() {
      return this.sizes.filter(
        e => e.fitmentNote === this.selectedSizeCategory
      );
    },
    ...mapState([
      'years',
      'makes',
      'models',
      'drivebodies',
      'submodels',
      'sizes',
      'selectedYear',
      'selectedMake',
      'selectedModel',
      'selectedDriveBody',
      'selectedSubModel',
      'selectedSize'
    ])
  },
  methods: {
    ...mapActions([
      'fetchYears',
      'setYear',
      'setMake',
      'setModel',
      'setDriveBody',
      'setSubModel',
      'setSize'
    ]),
    getSizeDescription(size) {
      if (size.isStag) {
        return `${Number(size.wheelSizeFront)}" Front, ${Number(
          size.wheelSizeRear
        )}" Rear (staggered)`;
      } else {
        return `${Number(size.wheelSizeFront)}" ${size.fitmentNote}`;
      }
    },
    freeze(val) {
      return JSON.parse(JSON.stringify(val));
    }
  },
  created() {
    this.fetchYears();
  },
  watch: {
    sizesForSelectedCategory(next, prev) {
      if (next.length === 1 && JSON.stringify(next) !== JSON.stringify(prev)) {
        //this.size = next[0].sizeId;
        this.$nextTick(() => {
          this.setSize(next[0].sizeId);
        });
      } else {
        this.setSize(null);
        //this.size = null;
      }
    },
    sizeCategories(next, prev) {
      if (next.length === 1 && JSON.stringify(next) !== JSON.stringify(prev)) {
        this.selectedSizeCategory = next[0];
      }
    },
    submodels(next, prev) {
      //console.log('submodels A and B', this.freeze(next), this.freeze(prev));
      //console.log('EQ:', JSON.stringify(next) === JSON.stringify(prev));
      if (next.length === 1 && JSON.stringify(next) !== JSON.stringify(prev)) {
        //console.log('PRESELECT!');
        this.submodel = next[0].fmk;
      }
    }
  }
};
</script>

<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
