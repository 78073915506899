import Vue from 'vue';
import Vuex from 'vuex';
//import { initFetcher, Vehicles } from '@/lib/IConfiguratorAPI';
import { Vehicles as VehiclesFG } from '@/lib/FitmentAtlasApi';
import { Vehicles as VehiclesIC } from '@/lib/HubApi';

Vue.use(Vuex);

/** @namespace make.makeid **/
/** @namespace model.modelid **/
/** @namespace drivebody.strDriveBody **/
/** @namespace submodel.submodelid **/
/** @namespace size.sizeid **/
export default function makeStore({ selectionCallback, configuratorId }) {
  if (!selectionCallback) {
    selectionCallback = selection => selection;
  }

  // initFetcher(configuratorId);

  const state = () => ({
    years: [],
    makes: [],
    models: [],
    drivebodies: [],
    submodels: [],
    sizes: [],
    selectedYear: null,
    selectedMake: null,
    selectedModel: null,
    selectedDriveBody: null,
    selectedSubModel: null,
    selectedSize: null,
    selectedSmartSubmodel: null
  });

  const getters = {
    selectedYear: state => state.selectedYear,
    selectedMake: state => state.selectedMake,
    selectedModel: state => state.selectedModel,
    selectedDriveBody: state => state.selectedDriveBody,
    selectedSubModel: state => state.selectedSubModel,
    selectedSize: state => state.selectedSize,
    selectedVehicle: state => ({
      year: state.years.find(year => year.key === state.selectedYear),
      make: state.makes.find(make => make.key === state.selectedMake),
      model: state.models.find(model => model.key === state.selectedModel),
      drivebody: state.drivebodies.find(
        drivebody => drivebody.id === state.selectedDriveBody
      ),
      submodel: state.submodels.find(
        submodel => submodel.fmk === state.selectedSubModel
      ),
      size: state.sizes.find(size => size.sizeId === state.selectedSize),
      availableYears: state.years,
      availableMakes: state.makes,
      availableModels: state.models,
      availableDriveBodies: state.drivebodies,
      availableSubModels: state.submodels,
      availableSizes: state.sizes
    })
  };

  const actions = {
    async fetchYears({ commit }) {
      try {
        const response = await VehiclesFG.years();
        commit('UPDATE_YEARS', response.data.result);
        return response.data.years;
      } catch (err) {
        throw err;
      }
    },
    async fetchMakes({ commit, getters }) {
      try {
        const response = await VehiclesFG.makes({
          YearID: getters.selectedYear
        });
        commit('UPDATE_MAKES', response.data.result);
      } catch (err) {
        throw err;
      }
    },
    async fetchModels({ commit, getters }) {
      try {
        const response = await VehiclesFG.models({
          YearID: getters.selectedYear,
          MakeID: getters.selectedMake
        });
        commit('UPDATE_MODELS', response.data.result);
      } catch (err) {
        throw err;
      }
    },
    async fetchSmartSubmodels({ commit, getters }) {
      // TODO: this is the new SmartSubmodels
      try {
        const response = await VehiclesFG.smartSubmodels({
          YearID: getters.selectedYear,
          MakeID: getters.selectedMake,
          ModelID: getters.selectedModel,
          ProductType: 'wheel'
        });
        const smartSubmodels = response.data.result.map((e, idx) => {
          e.id = idx;
          return e;
        });
        commit('UPDATE_DRIVEBODIES', smartSubmodels);
      } catch (err) {
        throw err;
      }
    },
    async fetchSmartSizes({ commit, state, getters }) {
      // TODO: this is the new SmartSizes
      //console.log(state.selectedSmartSubmodel);
      try {
        const response = await VehiclesFG.smartSizes({
          YearID: getters.selectedYear,
          MakeID: getters.selectedMake,
          ModelID: getters.selectedModel,
          SubmodelId: getters.selectedVehicle.drivebody.vcdB_SubmodelID,
          BodyTypeId: getters.selectedVehicle.drivebody.vcdB_BodyTypeID,
          DriveTypeId: getters.selectedVehicle.drivebody.vcdB_DriveTypeID,
          CustomNote: getters.selectedVehicle.drivebody.customNote,
          ProductType: 'wheel'
        });
        const smartSizes = response.data.result.map((e, idx) => {
          e.id = idx;
          return e;
        });
        commit('UPDATE_SUBMODELS', smartSizes);
      } catch (err) {
        throw err;
      }
    },
    async fetchSizes({ commit, getters }) {
      try {
        const response = await VehiclesIC.getSizesByFMK({
          fmk: getters.selectedSubModel,
          configId: configuratorId
        });
        commit('UPDATE_SIZES', response.data);
      } catch (err) {
        throw err;
      }
    },
    async setYear({ commit, dispatch, getters }, year) {
      try {
        commit('SELECT_YEAR', year);
        dispatch('fetchMakes');
        selectionCallback(getters.selectedVehicle);
      } catch (err) {
        throw err;
      }
    },
    async setMake({ commit, dispatch, getters }, make) {
      try {
        commit('SELECT_MAKE', make);
        dispatch('fetchModels');
        selectionCallback(getters.selectedVehicle);
      } catch (err) {
        throw err;
      }
    },
    async setModel({ commit, dispatch, getters }, model) {
      try {
        commit('SELECT_MODEL', model);
        dispatch('fetchSmartSubmodels');
        selectionCallback(getters.selectedVehicle);
      } catch (err) {
        throw err;
      }
    },
    async setDriveBody({ commit, dispatch, getters }, drivebody) {
      // TODO: rename setSmartSize
      try {
        commit('SELECT_DRIVEBODY', drivebody);
        dispatch('fetchSmartSizes');
        //dispatch('fetchSizes');
        selectionCallback(getters.selectedVehicle);
      } catch (err) {
        throw err;
      }
    },
    async setSubModel({ commit, dispatch, getters }, submodel) {
      try {
        commit('SELECT_SUBMODEL', submodel);
        dispatch('fetchSizes');
        selectionCallback(getters.selectedVehicle);
      } catch (err) {
        throw err;
      }
    },
    async setSize({ commit, getters }, size) {
      try {
        commit('SELECT_SIZE', size);
        selectionCallback(getters.selectedVehicle);
      } catch (err) {
        throw err;
      }
    }
  };

  const mutations = {
    UPDATE_YEARS(state, years) {
      state.years = years;
    },
    UPDATE_MAKES(state, makes) {
      state.makes = makes;
    },
    UPDATE_MODELS(state, models) {
      state.models = models;
    },
    UPDATE_DRIVEBODIES(state, drivebodies) {
      state.drivebodies = drivebodies;
    },
    UPDATE_SUBMODELS(state, submodels) {
      state.submodels = submodels;
    },
    UPDATE_SIZES(state, sizes) {
      state.sizes = sizes;
    },
    SELECT_YEAR(state, year) {
      state.selectedYear = year;

      state.makes = [];
      state.models = [];
      state.drivebodies = [];
      state.submodels = [];
      state.sizes = [];
      state.selectedMake = null;
      state.selectedModel = null;
      state.selectedDriveBody = null;
      state.selectedSubModel = null;
      state.selectedSize = null;
    },
    SELECT_MAKE(state, make) {
      state.selectedMake = make;

      state.models = [];
      state.drivebodies = [];
      state.submodels = [];
      state.sizes = [];
      state.selectedModel = null;
      state.selectedDriveBody = null;
      state.selectedSubModel = null;
      state.selectedSize = null;
    },
    SELECT_MODEL(state, model) {
      state.selectedModel = model;

      state.drivebodies = [];
      state.submodels = [];
      state.sizes = [];
      state.selectedDriveBody = null;
      state.selectedSubModel = null;
      state.selectedSize = null;
    },
    SELECT_DRIVEBODY(state, drivebody) {
      state.selectedDriveBody = drivebody;
      state.selectedSmartSubmodel = drivebody;

      state.submodels = [];
      state.sizes = [];
      state.selectedSubModel = null;
      state.selectedSize = null;
    },
    SELECT_SUBMODEL(state, submodel) {
      state.selectedSubModel = submodel;

      state.sizes = [];
      state.selectedSize = null;
    },
    SELECT_SIZE(state, size) {
      state.selectedSize = size;
    }
  };
  return new Vuex.Store({
    state,
    getters,
    actions,
    mutations
  });
}
