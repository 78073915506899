/*
  FitmentAtlas.API 1.1
  https://api.fitmentatlas.com/swagger/index.html?urls.primaryName=V1.1
 */

import axios from 'axios';
import config from '@/lib/config';

const fetcher = axios.create({
  baseURL: config.API_BASE_URI,
  headers: {
    'FG-ApiKey': config.API_KEY
  }
});
export const Vehicles = {
  byFMK(fmk) {
    return fetcher.get(`vehicles/${fmk}`);
  },
  vehicle(filter = {}) {
    // filter = {YearId*, MakeId*, ModelId*, Body*, Option*}
    return fetcher.get('vehicles', filter);
  },
  years(filter = {}) {
    // filter = {MakeId, ModelId, Body, Option}
    return fetcher.get('vehicles/years', { params: filter });
  },
  makes(filter = {}) {
    // filter = {YearId, ModelId, Body, Option}
    return fetcher.get('vehicles/makes', { params: filter });
  },
  models(filter = {}) {
    // filter = {YearId*, MakeId*, Body, Option}
    return fetcher.get('vehicles/models', { params: filter });
  },
  smartModels(filter = {}) {
    // filter = {YearId*, MakeId*, ProductType}
    return fetcher.get('vehicles/SmartModels', { params: filter });
  },
  smartSubmodels(filter = {}) {
    // filter = {YearId*, MakeId*, ModelId*, ProductType}
    return fetcher.get('vehicles/SmartSubmodels', { params: filter });
  },
  smartSizes(filter = {}) {
    // filter = {YearId*, MakeId*, ModelId*, SubmodelId, BodyTypeId, DriveTypeId, CustomNote, ProductType}
    return fetcher.get('vehicles/SmartSizes', { params: filter });
  },
  bodies(filter = {}) {
    // filter = {YearId*, MakeId*, ModelId*, Option}
    return fetcher.get('vehicles/bodies', { params: filter });
  },
  options(filter = {}) {
    // filter = {YearId*, MakeId*, ModelId*, Body*}
    return fetcher.get('vehicles/options', { params: filter });
  },
  minusSizesByFMK(fmk) {
    return fetcher.get(`vehicles/${fmk}/minussizes`);
  },
  plusSizesByFMK(fmk) {
    return fetcher.get(`vehicles/${fmk}/plussizes`);
  },
  liftedSizesByFMK(fmk) {
    return fetcher.get(`vehicles/${fmk}/liftedsizes`);
  },
  sizeGroupsByChassis(chassisId) {
    return fetcher.get(`chassis/${chassisId}/PlusAndMinusGroups`);
  }
};

export const Tires = {
  oeSizesByFMK(fmk) {
    return fetcher.get(`retailers/tires/OESizeByFMK`, {
      params: { fmk: fmk }
    });
  },
  plusSizesByFMK(fmk) {
    return fetcher.get(`retailers/tires/PlusSizesByFMK`, {
      params: { fmk: fmk }
    });
  },
  liftedSizesByFMK(fmk) {
    return fetcher.get(`retailers/tires/LiftedSizesByFMK`, {
      params: { fmk: fmk }
    });
  },
  minusSizesByFMK(fmk) {
    return fetcher.get(`retailers/tires/MinusSizesByFMK`, {
      params: { fmk: fmk }
    });
  },
  allSizesByFMK(fmk) {
    return fetcher.get(`retailers/tires/AllSizesByFMK`, {
      params: { fmk: fmk }
    });
  },
  tireSpecsByPartNumber(manufacturerProductCode) {
    return fetcher.get(`TireAtlas`, {
      params: {
        brandId: 64,
        manufacturerProductCode: manufacturerProductCode
      }
    });
  }
};
