export default {
  // API_KEY: 'hzgVRLXmcJQXtFpqCiSmas1k5wS5XKHO',
  API_KEY: 'oP7nFDS188hrNUUvZ4n0RsrJuB5YhbIc',
  API_BASE_URI: 'https://api.fitmentatlas.com/v1.1/services',
  VISUALIZER_KEY: null,
  VISUALIZER_ID: null,
  setup(opts) {
    this.API_KEY = opts.API_KEY;
    this.API_BASE = opts.API_BASE;
    this.VISUALIZER_KEY = opts.visualizerKey;
    this.VISUALIZER_ID = opts.VISUALIZER_ID;
    return this;
  }
};
